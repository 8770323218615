import React from "react";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <footer>
      <div>
        <ul>
          <li>
            <Link to='/'>Home</Link>
          </li>
          {/* <li>
            <Link to="/about">About</Link>
          </li> */}
          <li>
            <Link to='/gallery'>Gallery</Link>
          </li>
          <li>
            <Link to='/rsvp'>RSVP</Link>
          </li>
        </ul>
        <hr />
        <p
          style={{
            width: "100%",
            fontSize: "14px",
            maxWidth: "700px",
            marginBottom: "2rem",
          }}
        >
          The Roast is a private event and has no affiliation or financial support from any third party. This Event is privately funded and all
          parties in attendance are coming at their own accord and are not required to attend.
        </p>
        <p>© 2021 - Developed by Gavin Carter</p>
      </div>
    </footer>
  );
};

export default Footer;
