import React from "react";
import "../base.css";
import { Link } from "gatsby";
import Footer from "./footer";
import { slide as Menu } from "react-burger-menu";

const Template = ({ children }) => {
  return (
    <div>
      <Menu right width="280px">
        <Link style={{ textDecoration: "none" }} to="/">
          Home
        </Link>
        <Link style={{ textDecoration: "none" }} to="/gallery">
          Gallery
        </Link>
        <Link style={{ textDecoration: "none" }} to="/rsvp">
          RSVP
        </Link>
      </Menu>
      {/* <nav role="navigation">
        <ul className="navigation">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/blog/">Blog</Link>
          </li>
        </ul>
      </nav> */}

      {children}
      <Footer />
    </div>
  );
};

export default Template;
